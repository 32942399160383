<template>
  <v-container fluid class="pt-0">
    <v-row class="text-center py-0 my-0 px-6">
      <v-col cols="12" md="12" class="mx-auto">
        <div class="shell">
          <h1>
            Your
            <span class="verb">clients</span> are worth more with AnnuityCheck.
          </h1>
        </div>

        <v-img
          style="width: 380px; max-width: 80vw"
          class="mx-auto my-0"
          src="https://annuityscore.io/static/images/ipad_2.png"
        ></v-img>
        <p class="intro" style="font-family: Basetica-black; font-size: 34px">
          The only end-to-end annuity business solution.
        </p>
        <br />
        <v-row>
          <v-col cols="12" sm="8" class="text-center mx-auto">
            <v-btn
              v-if="!$store.state.user.id"
              x-large
              class="mb-6 mt-0"
              color="primary"
              dark
              rounded
              to="/signup"
            >
              Get Started
            </v-btn>
            <v-btn
              v-else
              x-large
              class="my-auto"
              color="primary"
              dark
              rounded
              to="/presentation"
            >
              Create Campaign
            </v-btn>

            <!-- <v-text-field
              style="width: 400px; max-width: 80vw;"
              class="shrink mx-auto zip-input"
              outlined
              label="ZIP Code"
              prepend-inner-icon="mdi-map-marker"
            >
              <template v-slot:append-outer class="my-auto">
                <v-btn large class="my-auto" color="#003a6d" dark to="/signup">
                  Get Started
                </v-btn>
              </template>
            </v-text-field> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="lightblue pt-6 px-6">
      <v-col cols="12" md="6" class="mx-auto text-center">
        <h2>Plan, Present, Follow-up.</h2>
        <hr
          class="solid mx-auto mb-3 mt-3"
          style="
            width: 100px;
            background-color: #003a6d;
            border: none;
            height: 2px;
          "
        />
        <p class="intro">
          Annuities are complex. Marketing them doesn’t have to be.
        </p>
      </v-col>
    </v-row>
    <v-row class="lightblue pb-6 text-center px-6">
      <v-container>
        <v-row class="hidden-sm-and-down">
          <v-col>
            <v-img
              src="@/assets/icons/Calendar-Settings.svg"
              height="50"
              class="mx-auto mb-1 mt-5"
              width="50"
              contain
            >
            </v-img>
          </v-col>
          <v-col>
            <v-img
              src="@/assets/icons/Collaboration-Global.svg"
              height="50"
              class="mx-auto mb-1 mt-5"
              width="50"
              contain
            >
            </v-img>
          </v-col>
          <v-col>
            <v-img
              src="@/assets/icons/Metrics.svg"
              height="53"
              class="mx-auto mb-1 mt-5"
              width="50"
              contain
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-img
              src="@/assets/icons/Calendar-Settings.svg"
              height="50"
              class="mx-auto mb-1 mt-5 hidden-md-and-up"
              width="50"
              contain
            >
            </v-img>

            <h3>Setup with ease</h3>
            <p class="explainer">
              Forget the confusion. Just choose an event date, and AnnuityCheck
              will walk you through the rest.
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              src="@/assets/icons/Collaboration-Global.svg"
              height="50"
              class="mx-auto mb-1 mt-5 hidden-md-and-up"
              width="50"
              contain
            >
            </v-img>
            <h3>Draw in the crowds</h3>
            <p class="explainer">
              Promote your seminar with our proven invitations, registration
              page, and automated email and text reminders.
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              src="@/assets/icons/Metrics.svg"
              height="50"
              class="mx-auto mb-1 mt-5 hidden-md-and-up"
              width="50"
              contain
            >
            </v-img>
            <h3>Educate with confidence</h3>
            <p class="explainer">
              Educate with our world-class presentation and set appointments
              with attendees to evaluate their annuities.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="darkblue pt-6 px-6" dark>
      <v-container>
        <v-row>
          <v-col cols="12" md="6" class="order-md-first text-center">
            <h2>Evaluate. Calculate. Motivate.</h2>
            <hr
              class="solid mx-auto mb-3 mt-3"
              style="
                width: 100px;
                background-color: white;
                border: none;
                height: 2px;
              "
            />

            <p class="intro">
              Calculate performance on a current annuity in less than 30 seconds
              and provide clients with their AnnuityScore.
            </p>
          </v-col>
          <v-col cols="12" md="6" class="order-first">
            <v-img
              src="@/assets/img/ud_calculator.svg"
              height="400"
              class="mx-auto mb-1 mt-5"
              width="400"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="pt-6 px-6">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-img
              src="@/assets/img/ud_all_data.svg"
              height="400"
              class="mx-auto mb-1 mt-5"
              width="400"
              contain
            ></v-img>
          </v-col>

          <v-col cols="12" md="6" class="text-center">
            <h2>
              Smart Withdrawals.<br />
              Really Smart.
            </h2>
            <hr
              class="solid mx-auto mb-3 mt-3"
              style="
                width: 100px;
                background-color: #003a6d;
                border: none;
                height: 2px;
              "
            />

            <p class="intro">
              Increase client withdrawals up to 40% using our proprietary Smart
              Withdrawals™ technology.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="lightblue pt-6 px-6">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" class="order-md-first text-center">
            <h2>Larger, more compliant, transfers</h2>
            <hr
              class="solid mx-auto mb-3 mt-3"
              style="
                width: 100px;
                background-color: #003a6d;
                border: none;
                height: 2px;
              "
            />

            <p class="intro">
              Safely increase transaction size up to 650% using our
              Zero-Loss-Transfer process.
            </p>
          </v-col>
          <v-col cols="12" md="6" class="order-first">
            <v-img
              src="@/assets/img/four-trillion.svg"
              height="400"
              class="mx-auto mb-1 mt-5"
              width="400"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="darkblue pt-6 px-6">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-img
              src="@/assets/img/ud_data_report.svg"
              height="400"
              class="mx-auto mb-1 mt-5"
              width="400"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <h2>End-to-End Annuity Marketing</h2>
            <hr
              class="solid mx-auto mb-3 mt-3"
              style="
                width: 100px;
                background-color: white;
                border: none;
                height: 2px;
              "
            />

            <p class="intro">
              We take care of your entire seminar process with 54 cent mail, the
              industry's best-performing presentation, prospect tracking, and
              bleeding-edge deal closing software.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<style scoped>
.lightblue {
  background-color: #003a6d11;
}
.darkblue {
  background-color: #003a6d;
  color: white;
}
.zip-input /deep/ .v-input__slot {
  margin: 0 !important;
}

.zip-input /deep/ .v-text-field__details {
  display: none !important;
}
.zip-input /deep/ .v-input__append-outer {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.darkblue .paper {
  stroke: white !important;
}

h1,
h2 {
  font-family: Basetica-black;
}

h1 {
  font-size: 45px;
}
h2 {
  font-size: 44px;
}

.intro {
  font-size: 25px;
  font-weight: 500;
  padding-top: 12px;
}
.explainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}
@-moz-keyframes fade-it {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-it {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-it {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-fade-in-verb {
  animation-name: fade-it;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-it;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
}
.shell {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.shell h1 {
  font-size: 45px;
}

@media only screen and (max-width: 600px) {
  .shell h1 {
    font-size: 35px;
    margin: 0;
  }
}
.verb.js-block {
  display: inline-block;
}
.verb.js-hide {
  display: none;
  opacity: 0;
}
.verb {
  display: inline-block;
  visibility: visible;
  border-bottom: 1px solid;
}
.verb {
  display: inline !important;
  color: #22abe1;
  border: none;
}
</style>

<script>
export default {
  name: "AnnuityScore",
  async mounted() {
    // eslint-disable-next-line
    this.words().init();
  },
  methods: {
    words() {
      var words = [
          "clients",
          "prospects",
          "cases",
          "mailers",
          "seminars",
          "referrals",
          "workshops",
          "webinars",
        ],
        el = document.querySelector(".verb"),
        currentIndex = 0,
        currentWord,
        prevWord,
        duration = 3000;

      var _getIndex = function (max) {
        // currentIndex = Math.floor(Math.random() * (max - min + 1)) + min;
        currentIndex = currentIndex + 1;
        //Generates a random number between beginning and end of words array
        return currentIndex % (max + 1);
      };

      // var _getWord = function(index) {
      //   currentWord = words[index];

      //   return currentWord;
      // };

      var _clear = function () {
        setTimeout(function () {
          el.className = "verb";
        }, duration / 4);
      };

      var _toggleWord = function (duration) {
        setInterval(function () {
          //Stores value of previous word
          prevWord = currentWord;

          //Generate new current word
          // console.log(_getIndex(words.length - 1));
          currentWord = words[_getIndex(words.length - 1)];

          //Generate new word if prev matches current
          if (prevWord === currentWord) {
            currentWord = words[_getIndex(words.length - 1)];
          }

          //Swap new value
          el.innerHTML = currentWord;

          //Clear class styles
          _clear();

          //Fade in word
          el.classList.add("js-block", "js-fade-in-verb");
        }, duration);
      };

      var _init = function () {
        _toggleWord(duration);
      };

      //Public API
      return {
        init: function () {
          _init();
        },
      };
    },
  },
  components: {},
};
</script>
